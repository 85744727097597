import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
import FtnJwtSnippet from '../../../snippets/jwt-payloads/finnish-trust-network';
import eca from './files/telia-criipto_end_customer_agreement_12.7.2019.pdf';
import serviceDesc from './files/Telia-Tunnistus palvelukuvaus-20190529-en.pdf';
import terms from './files/Telia-Tunnistus-Yleiset-ehdot-yrityksille_31.5.2019-en_gb.pdf';
export const _frontmatter = {
  "product": "verify",
  "category": "eIDs",
  "sort": 6,
  "title": "Finnish Trust Network",
  "subtitle": "Learn more about Finnish Trust Network token contents, how to create test users and how to gain access to production."
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Highlight = makeShortcode("Highlight");
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`JWT/Token examples`}</h2>
    <Highlight icon="info" mdxType="Highlight">
      <p>{`The level of assurance for all Finnish authenticators is: Substantial`}</p>
    </Highlight>
    <h3>{`BankID`}</h3>
    <FtnJwtSnippet mdxType="FtnJwtSnippet" />
    <h3>{`Mobillivarmenne`}</h3>
    <p>{`Same as BankID, except the `}<inlineCode parentName="p">{`satu`}</inlineCode>{` property will have a value as well.`}</p>
    <h2>{`Test users`}</h2>
    <p>{`A set list of test users for the different banks are given below. Sometimes the test credentials will be shown also on the actual login page at the bank.`}</p>
    <h3>{`Aktia`}</h3>
    <p>{`Käyttäjätunnus: `}<inlineCode parentName="p">{`12345678`}</inlineCode><br />{`
Salasana: `}<inlineCode parentName="p">{`123456`}</inlineCode><br />{`
Turvaluku: `}<inlineCode parentName="p">{`1234`}</inlineCode><br />{`
Turvaluku 2: `}<inlineCode parentName="p">{`1234`}</inlineCode><br /></p>
    <h3>{`Danske Bank`}</h3>
    <p>{`Must use your own customer credentials. No real cash is withdrawn from your bank account.`}</p>
    <h3>{`Handelsbanken`}</h3>
    <p>{`Käyttäjätunnus: `}<inlineCode parentName="p">{`11111111`}</inlineCode><br />{`
Salasana: `}<inlineCode parentName="p">{`123456`}</inlineCode><br />{`
Turvaluku: `}<inlineCode parentName="p">{`123456`}</inlineCode><br />{`
Turvaluku 2: `}<inlineCode parentName="p">{`123456`}</inlineCode><br /></p>
    <h3>{`LähiTapiola`}</h3>
    <p>{`Käyttäjätunnus: `}<inlineCode parentName="p">{`12345678`}</inlineCode><br />{`
Salasana: any numbers`}<br />{`
Tunnusluku: any four numbers`}<br />{`
Tunnusluku 2: any four numbers`}<br /></p>
    <h3>{`Nordea`}</h3>
    <p>{`Tunnus: `}<inlineCode parentName="p">{`123456`}</inlineCode><br />{`
Salasana: `}<inlineCode parentName="p">{`1234`}</inlineCode><br />{`
Vahvistustunnus: any four numbers`}<br /></p>
    <h3>{`OP / Osuuspankki`}</h3>
    <p>{`Käyttäjätunnus: `}<inlineCode parentName="p">{`123456`}</inlineCode><br />{`
Salasana: `}<inlineCode parentName="p">{`7890`}</inlineCode><br />{`
Avainluku: any four numbers`}<br /></p>
    <p>{`POP Bank
Käyttäjätunnus: `}<inlineCode parentName="p">{`11111111`}</inlineCode><br />{`
Salasana: `}<inlineCode parentName="p">{`123456`}</inlineCode><br />{`
Turvaluku: `}<inlineCode parentName="p">{`123456`}</inlineCode><br />{`
Turvaluku 2: `}<inlineCode parentName="p">{`123456`}</inlineCode><br /></p>
    <h3>{`S-Bank`}</h3>
    <p>{`Käyttäjätunnus: `}<inlineCode parentName="p">{`12345678`}</inlineCode><br />{`
Salasana: any numbers`}<br />{`
Tunnusluku: any four numbers`}<br />{`
Tunnusluku 2: any four numbers`}<br /></p>
    <h3>{`Savings bank`}</h3>
    <p>{`Käyttäjätunnus: `}<inlineCode parentName="p">{`11111111`}</inlineCode><br />{`
Salasana: `}<inlineCode parentName="p">{`123456`}</inlineCode><br />{`
Turvaluku: `}<inlineCode parentName="p">{`123456`}</inlineCode><br />{`
Turvaluku 2: `}<inlineCode parentName="p">{`123456`}</inlineCode><br /></p>
    <h3>{`Ålandsbanken`}</h3>
    <p>{`Käyttäjätunnus: `}<inlineCode parentName="p">{`12345678`}</inlineCode><br />{`
Salasana: any numbers`}<br />{`
Tunnusluku: any four numbers`}<br />{`
Tunnusluku 2: any four numbers`}<br /></p>
    <h3>{`Oma Säästöpankki`}</h3>
    <p>{`Käyttäjätunnus: `}<inlineCode parentName="p">{`11111111`}</inlineCode>{` / `}<inlineCode parentName="p">{`22222222`}</inlineCode><br />{`
Salasana: `}<inlineCode parentName="p">{`123456`}</inlineCode><br />{`
Turvaluku: `}<inlineCode parentName="p">{`123456`}</inlineCode><br />{`
Turvaluku 2: `}<inlineCode parentName="p">{`123456`}</inlineCode><br /></p>
    <h2>{`Ordering a production agreement`}</h2>
    <Highlight icon="file-lines" mdxType="Highlight">
      <p><strong parentName="p">{`Prerequisites for getting access to the Finnish Trust Network`}</strong></p>
      <p>{`There are no formal prerequisites to be allowed to accept FTN logins in your application. But as described below you
must fill out the necessary End Customer Agreement.`}</p>
      <p>{`The filled out agreement will be reviewed by Telia Finland.`}</p>
    </Highlight>
    <p>{`Access to the Finnish Trust Network, FTN, (BankID and the Mobile Certificate, `}<em parentName="p">{`Mobiilivarmenne`}</em>{`) is provided by Telia Finland. In order to start using FTN you must accept the terms from Telia.  `}</p>

    <p>{`You need to `}<a href={eca} download>{`download the End Customer Agreement (PDF)`}</a>{`, fill it out, and then return a signed copy to `}</p>
    <a href="mailto:support@criipto.com?subject=FTN End Customer Agreement for ...">support@criipto.com</a>.
    <p>{`Other relevant documents in this context are the `}<a href={serviceDesc}>{`service description`}</a>{` and the `}<a href={terms}>{`general terms`}</a>{` from Telia Finland.`}</p>
    <p>{`Once you have sent the signed End Customer Agreement we will enable production access for you tenant.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      